import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { apiClientService } from '../../../domain/services/apiClientService';
import { dateService } from '../../../domain/services/dateService';
import { getDataSubdirectory } from '../../../domain/utils/contentUtils';
import { Image } from '../../Image';
import { SmartLink } from '../../SmartLink';
import { useGeoLocation } from '../../../hooks/useGeoLocation';
import { theme } from '../../../domain/theme';
import { imageResizerService } from '../../../domain/services/imageResizerService';
import { ParagraphWrapper } from '../ParagraphWrapper';

const PromoBannerParagraphInner = props => {
  const { content, isAboveTheFold } = props;

  const [promoBannerContent, setPromoBannerContent] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const dataSubDir = getDataSubdirectory(content.id);
      const url = `/ajax/promo_banner/${dataSubDir}${content.id}.json`;
      const { response } = await apiClientService.getJson(url);
      setPromoBannerContent(response);
    };

    fetchData();
  }, []);

  const isVisible =
    useGeoLocation(
      promoBannerContent?.data?.content?.geolocationFilter,
      promoBannerContent?.data?.content?.countries,
      promoBannerContent
    ) &&
    dateService.isWithinDateRange(
      Math.floor(new Date().getTime() / 1000),
      promoBannerContent?.data?.content?.promoBannerStartDate?.timestamp,
      promoBannerContent?.data?.content?.promoBannerEndDate?.timestamp
    );

  const isPublished = promoBannerContent?.data?.content?.isPublished;
  const mobileImage =
    promoBannerContent?.data?.content?.PromoBannerMobileImage?.entity;
  const desktopImage =
    promoBannerContent?.data?.content?.PromoBannerDesktopImage?.entity;
  const bannerLink = promoBannerContent?.data?.content?.PromoBannerLink?.uri;
  const bannerLinkTarget =
    promoBannerContent?.data?.content?.PromoBannerLinkTarget;
  const bannerClassName = promoBannerContent?.data?.content?.PromoBannerClass;

  const styles = {
    main: css`
      width: 100%;
      aspect-ratio: ${imageResizerService.getVerticalRatio(
        mobileImage || desktopImage
      )};
      ${theme.mq.small_desktop} {
        aspect-ratio: ${imageResizerService.getVerticalRatio(
          desktopImage || mobileImage
        )};
      }
      margin: 0 auto;
      margin-bottom: ${theme.spacing('l')};
    `,

    mobileImage: css`
      display: block;
      ${theme.mq.small_desktop} {
        display: none;
      }
    `,

    desktopImage: css`
      display: none;
      ${theme.mq.small_desktop} {
        display: block;
      }
    `
  };

  if (promoBannerContent && isVisible && isPublished) {
    const mobileImageTag = (
      <div css={desktopImage ? styles.mobileImage : null}>
        <Image
          entity={mobileImage}
          loading={isAboveTheFold ? 'eager' : 'lazy'}
        />
      </div>
    );

    const desktopImageTag = desktopImage ? (
      <div css={styles.desktopImage}>
        <Image
          entity={desktopImage}
          loading={isAboveTheFold ? 'eager' : 'lazy'}
        />
      </div>
    ) : null;

    return bannerLink ? (
      <div css={styles.main}>
        <SmartLink
          to={bannerLink}
          target={bannerLinkTarget || '_self'}
          className={bannerClassName}
        >
          {mobileImageTag}
          {desktopImageTag}
        </SmartLink>
      </div>
    ) : (
      <div className={bannerClassName} css={styles.main}>
        {mobileImageTag}
        {desktopImageTag}
      </div>
    );
  }

  return <div css={styles.main} />;
};

PromoBannerParagraphInner.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool.isRequired
};

export const PromoBannerParagraph = ParagraphWrapper(PromoBannerParagraphInner);
